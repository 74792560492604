/* src/styles/FLoatingBubbleBackground.css */


.bubble-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* behind everything */
    overflow: hidden;
    background-color: black;
    display: flex; /* Add flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center;
    padding-top: 0vh; /* Add some top padding to move bubble down a bit if needed */
}

.bubble {
    --_float-distance: -20px;
    --_float-speed: 3000ms;
    --_size: 300px; /* Increased size */

    width: var(--_size);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    position: relative; /* Changed from absolute to relative for flexbox to work */
    /* Removed top and left to allow flexbox to center */


    backdrop-filter: blur(5px);
    box-shadow:
      inset 0 0.13vmin blue,
      inset 0 0.18vmin orange,
      inset 0.1vmin 0.1vmin orange;
    animation: floating var(--_float-speed) ease-in-out infinite;
}

  .bubble::before,
  .bubble::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }

  .bubble::before {
    inset: 0;
    backdrop-filter: blur(12px);
    background-image: conic-gradient(
      from -25deg at 80% 20%,
      transparent 85%,
      rgba(255, 255, 255, 0.7) 94%,
      transparent 94%
    );
    filter: blur(4px);
  }

  .bubble::after {
    inset: -3px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    z-index: -1;
  }

  @keyframes floating {
    0%, 100% {
      transform: translate(0);
    }
    50% {
      transform: translate(0, var(--_float-distance, -10px));
    }
  }