.motion-cards-section {
  padding: 4rem 2rem;
  background-color: #0b0b09;
  color: #fff;
  font-family: "Rubik", sans-serif;
}

.motion-cards-header {
  text-align: center;
  margin-bottom: 3rem;
}

.motion-cards-header h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #fff 0%, #989989 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.motion-cards-header p {
  color: #989989;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.motion-cards-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2.5rem;
  padding: 1rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* Clean card — no hover animation */
.motion-card {
  background: none;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  width: 320px;
  height: 100%;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  transition: none;
}

.card-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center; /* centers button horizontally */
  align-items: flex-end;    /* aligns it to bottom */
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 16px;
}

/* Fixed-position Explore button at bottom */
.motion-card-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #989989 0%, #b3b3a3 100%);
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(152, 153, 137, 0.3);
  z-index: 2;
  transition: transform 0.2s ease;
}

.motion-card-button:hover {
  transform: translateX(-50%) scale(1.08); /* Scale up in place */
  background: linear-gradient(90deg, #878777 0%, #a1a191 100%);
  box-shadow: 0 6px 20px rgba(152, 153, 137, 0.4);
}

/* Hide title/description since we're only using image + button */
.motion-card h3,
.motion-card p {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .motion-cards-container {
    gap: 1.5rem;
  }

  .motion-card {
    width: 100%;
    max-width: 340px;
  }

  .motion-cards-header h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .motion-cards-section {
    padding: 3rem 1rem;
  }

  .motion-cards-header h2 {
    font-size: 2rem;
  }

  .motion-cards-header p {
    font-size: 1rem;
  }

  .motion-card-button {
    padding: 0.4rem 1.2rem;
    font-size: 0.85rem;
  }
}
