/* src/styles/PartneringCarousel.css */

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");


.option {
  height: 100%;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1500px;
  height: 600px;
  margin: auto;
}

.option {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  min-width: 150px;
  margin: 10px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  transition: flex-grow 0.5s ease, border-radius 0.5s ease;
}

.option.active {
  flex-grow: 1.5;
  border-radius: 30px;
}

.shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
}

.label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.option.active .label {
  bottom: 20px;
  left: 20px;
}

.icon {
  width: 40px;
  height: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.info {
  margin-left: 10px;
  color: white;
}

.main {
  font-weight: bold;
  font-size: 1.2rem;
}

.sub {
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.5s;
}

.option.active .sub {
  opacity: 1;
}

.partnering-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b0b09;  /* Light gray background */
  padding: 20px;
}

.api-key-button {
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.option.active .api-key-button {
  opacity: 1;
} 