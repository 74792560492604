.animated-hero {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    background-color: #0b0b09;
    isolation: isolate;
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    text-align: center;
    padding: 0 2em;
    z-index: 2;
}

.nav h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 5vw;
    margin: 0;
    padding-bottom: 0.5rem;
    letter-spacing: 0.5rem;
    color: #FFF;
    transition: all 0.3s ease;
    z-index: 3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.slider h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 5vw;
    margin: 0;
    padding-bottom: 0.5rem;
    letter-spacing: 0.5rem;
    color: #FFF;
    transition: all 0.3s ease;
    z-index: 3;
}

.slider h2 {
    font-size: 2vw;
    letter-spacing: 0.3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #faebd7;
    z-index: 4;
}

h3.span {
    font-size: 3vw;
    letter-spacing: 0.5em;
    font-family: "Rubik", sans-serif;
    font-weight: 300;
    color: #faebd7;
    z-index: 4;
    margin-top: 10px;
    margin-bottom: 10px;
}

button.hero-button {
    padding: 20px;
    border: solid;
    color: #faebd7;
    background-color: transparent;
    border-radius: 10px;
    transition: 0.25s;
}

button.hero-button:hover {
    box-shadow: inset 10.0em 0 0 0 #fff;
    color: #0b0b09;
}

.loader span {
    color: #faebd7;
    text-shadow: 0 0 0 #faebd7;
    animation: loading 1s ease-in-out infinite alternate;
}

@keyframes loading {
    to {
        text-shadow: 1px 0 10px #fefefe;
        color: #989989;
    }
}

/* Add animation delays for each span */
.loader span:nth-child(2) { animation-delay: 0.1s; }
.loader span:nth-child(3) { animation-delay: 0.2s; }
.loader span:nth-child(4) { animation-delay: 0.3s; }
.loader span:nth-child(5) { animation-delay: 0.4s; }
.loader span:nth-child(6) { animation-delay: 0.5s; }
.loader span:nth-child(7) { animation-delay: 0.6s; }
.loader span:nth-child(8) { animation-delay: 0.7s; }
.loader span:nth-child(9) { animation-delay: 0.8s; }
.loader span:nth-child(10) { animation-delay: 0.9s; }
.loader span:nth-child(11) { animation-delay: 1.0s; }
.loader span:nth-child(12) { animation-delay: 1.1s; }
.loader span:nth-child(13) { animation-delay: 1.2s; }
.loader span:nth-child(14) { animation-delay: 1.3s; }
.loader span:nth-child(15) { animation-delay: 1.4s; }
.loader span:nth-child(16) { animation-delay: 1.5s; }
.loader span:nth-child(17) { animation-delay: 1.6s; }
.loader span:nth-child(18) { animation-delay: 1.7s; }
.loader span:nth-child(19) { animation-delay: 1.8s; }
.loader span:nth-child(20) { animation-delay: 1.9s; }
.loader span:nth-child(21) { animation-delay: 2.0s; }
.loader span:nth-child(22) { animation-delay: 2.1s; }
.loader span:nth-child(23) { animation-delay: 2.2s; }


/* ... continue for all spans ... */

/* Add your media queries from the original code here */

/* Add this new style */
#tsparticles {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
}

.hero-title {
    text-align: center;
    transition: all 0.3s ease;
}

.hero-title:hover {
    transform: translate3d(0, -10px, 22px);
}

.hero-title:hover h1 {
    color: #989989;
}

.hero-title h1 {
    margin: 0;
    line-height: 1.2;
    transition: all 0.3s ease;
}

.content-section {
    background-color: #0b0b09;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
}

.content-wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.content-wrapper h2 {
    color: #fff;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    font-family: "Josefin Sans", sans-serif;
    letter-spacing: 0.2rem;
}

.content-wrapper p {
    color: #989989;
    font-size: 1.5rem;
    line-height: 1.6;
    font-family: "Roboto", sans-serif;
}