/* src/styles/Header.css */

/* General Header Styles */
.header {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  position: fixed;
  top: 0;
  z-index: 1001;
  border-bottom: none;
  transition: all 0.3s ease;
}

/* Desktop Header Styles */
.desktop-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.desktop-header .header-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border: none;
}

/* Logo Styles */
.logo {
  position: relative;
  left: 0;
}

.logo a {
  font-size: 25px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

/* Navigation Styles */
.desktop-header nav {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-header nav ul {
  display: flex;
  gap: 70px;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

/* Navigation Link Styles */
.desktop-header nav ul li a,
.nav-link {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 15px;
  font-weight: 450;
  text-decoration: none;
  padding: 8px 16px;
  /* transition: color 0.3s ease, text-shadow 0.3s ease; */
  cursor: pointer;
  background: none;
  border: none;
}

/* Set consistent font weight for ALL navigation states */
.desktop-header nav ul li a,
.nav-link,
.desktop-header nav ul li button.nav-link,
.mobile-menu nav ul li a,
.mobile-menu nav ul li button.nav-link,
.dropdown-item,
.desktop-header nav ul li a.active,
.desktop-header nav ul li button.nav-link.active,
.dropdown-item.active,
.active > a,
.nav-link.active,
.mobile-menu nav ul li a.active,
.mobile-menu .dropdown-item.active {
  font-weight: 450 !important; /* Force consistent weight */
  transition: color 0.5s ease-in-out, text-shadow 0.5s ease-in-out;
}

/* Dropdown Button Alignment */
.desktop-header nav ul li button.nav-link {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Logo Styles */
.logo a, .mobile-logo a {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

/* Navigation Styles */
.desktop-header nav ul li {
  position: relative;
}

/* Active Link Highlighting */
.desktop-header nav ul li a.active,
.dropdown-item.active {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  /* Remove this line if it exists */
  /* font-weight: 600; */
}

/* Hover Effects with Glow */
.desktop-header nav ul li a:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--card-background);
  width: max-content;
  min-width: 160px;
  box-shadow: none;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1001;
  border: 1px solid #333;
}

.dropdown-menu-open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/* Dropdown Content */
.dropdown-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-menu-content a {
  display: block;
  padding: 10px 15px;
  color: #999999;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-size: 14px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

/* Dropdown Hover Glow Effect */
.dropdown-menu-content a:hover {
  color: var(--primary-color);
  background-color: var(--secondary-background);
}

/* Auth Buttons */
.auth-buttons .sign-in,
.auth-buttons .sign-up {
  margin-left: 10px;
  padding: 6px 14px;
  border: 1px solid var(--primary-color);
  border-radius: 18px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-buttons .sign-in {
  background-color: transparent;
  color: var(--primary-color);
}

.auth-buttons .sign-in:hover {
  background-color: var(--primary-color);
  color: white;
}

.auth-buttons .sign-up {
  background-color: var(--primary-color);
  color: white;
}

.auth-buttons .sign-up:hover {
  background-color: #ffffff;
}

/* Icon Container Styles */
.icon-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999999;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.icon-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

.icon-link span {
  font-size: 10px;
  margin-top: 4px;
  color: #999999;
}

/* Mobile Header Styles */
.mobile-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 16px;
  background-color: var(--background-color);
  box-shadow: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Mobile Menu Button Styles */
.mobile-menu-button button {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.mobile-menu-button button:hover {
  transform: scale(1.1);
}

/* Mobile Menu Overlay */
.mobile-menu-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 999;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Mobile Menu Styles */
.mobile-menu {
  width: 85%;
  height: 100%;
  background-color: var(--background-color);
  padding: 20px;
  animation: slideInLeft 0.3s ease;
  overflow-y: auto;
  border-radius: 0;
}

@keyframes slideInLeft {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

.mobile-menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu nav ul li {
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 15px;
}

.mobile-menu nav ul li:last-child {
  border-bottom: none;
}

.mobile-menu nav ul li a,
.mobile-menu nav ul li .nav-link {
  font-size: 18px;
  padding: 10px 0;
  display: block;
}

.mobile-menu nav ul li a.active,
.mobile-menu .dropdown-item.active {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  font-weight: inherit;
}

.mobile-menu nav ul li a:hover,
.mobile-menu nav ul li .nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

/* Auth Buttons for Mobile */
.auth-buttons-mobile {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.mobile-auth-buttons {
  margin-bottom: 25px;
}

.mobile-auth-buttons .icon-container {
  flex-direction: row;
  justify-content: space-around;
}

.mobile-auth-buttons .icon-link span {
  font-size: 12px;
}

.mobile-auth-buttons .mobile-sign-in,
.mobile-auth-buttons .mobile-sign-up {
  padding: 10px 0;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mobile-auth-buttons .mobile-sign-in {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.mobile-auth-buttons .mobile-sign-in:hover {
  background-color: var(--primary-color);
  color: white;
}

.mobile-auth-buttons .mobile-sign-up {
  background-color: var(--primary-color);
  color: white;
}

.mobile-auth-buttons .mobile-sign-up:hover {
  background-color: #0045d1;
}

/* Mobile Dropdown Menu Styles */
.mobile-menu .dropdown-menu {
  position: static;
  transform: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.mobile-menu .dropdown-menu-content {
  padding-left: 15px;
}

.mobile-menu .dropdown-menu-content a {
  padding: 8px 0;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (min-width: 769px) {
  .mobile-header {
    display: none;
  }
}

@media (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
  }

  .header-content {
    padding: 0;
  }

  .auth-buttons .icon-container {
    gap: 10px;
  }

  body {
    padding-top: 60px;
  }

  .mobile-header .mobile-logo a {
    font-size: 24px;
  }

  .mobile-menu-button button {
    padding: 8px;
  }
}

/* Ensure Full-Width Coverage */
.header,
.desktop-header .header-content,
.mobile-header {
  width: 100%;
}

.desktop-header .header-content,
.mobile-menu {
  max-width: 1200px;
  margin: 0 auto;
}

/* Remove Border/Frame - Ensure No Borders in Header Containers */
.desktop-header .header-content {
  border: none;
}

/* Active Link Styling */
.active > a,
.dropdown-item.active {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  /* Remove font-weight change */
  font-weight: inherit;
}

/* Button Styles in Dropdown to Remove Default Button Appearance */
.desktop-header nav ul li button.nav-link {
  color: #999999;
  font-size: 15px; /* Match the size of other nav links */
  font-weight: 500;
  text-decoration: none;
  padding: 8px 16px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

.dropdown-menu a {
  display: block;
}

/* Ensure <a> links have the same display properties
.desktop-header nav ul li a.nav-link {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
} */

/* Hover Effects with Glow - for all navigation elements */
.desktop-header nav ul li a:hover,
.desktop-header nav ul li button.nav-link:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
}

/* Active Link Highlighting */
.desktop-header nav ul li a.active,
.desktop-header nav ul li button.nav-link.active,
.dropdown-item.active {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  /* Remove font-weight change */
  font-weight: inherit;
}

/* Update mobile menu text colors */
.mobile-menu-item {
  color: #999999;
  border-bottom: 1px solid #333;
}

/* Also update mobile menu button if needed */
.mobile-menu nav ul li button.nav-link {
  font-size: 21px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

/* Header Scroll Styles - Updated for non-home pages */
.header:not(.home-page),
.header.scrolled {
  background-color: rgba(11, 11, 9, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

/* Mobile Header Styles - Updated for non-home pages */
.mobile-header:not(.home-page),
.mobile-header.scrolled {
  background-color: rgba(11, 11, 9, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

/* Desktop Header Scroll Styles */
.desktop-header.scrolled {
  background-color: transparent;
}

/* Ensure dropdown menus match the header style */
.header:not(.home-page) .dropdown-menu,
.header.scrolled .dropdown-menu {
  background-color: rgba(11, 11, 9, 0.9);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(51, 51, 51, 0.3);
}

/* Adjust mobile menu overlay when header is scrolled */
.header.scrolled .mobile-menu-overlay {
  top: 60px;
}

.header.scrolled .mobile-menu {
  background-color: rgba(11, 11, 9, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

/* Add these styles to handle the button appearance */
.nav-link {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 16px;
  color: #999999;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
}

/* Add smooth scrolling to the html element */
html {
  scroll-behavior: smooth;
}

/* Update the active state styles */
.nav-link.active {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  /* Remove font-weight change to prevent size change */
  font-weight: inherit; 
}

/* Ensure smooth transitions */
.nav-link {
  transition: color 0.3s ease, text-shadow 0.3s ease;
  /* Remove font-weight from transition to prevent size change */
}

/* Improve transition smoothness for all navigation elements */
.desktop-header nav ul li a,
.nav-link,
.desktop-header nav ul li button.nav-link,
.mobile-menu nav ul li a,
.mobile-menu nav ul li button.nav-link,
.dropdown-item {
  /* Increase transition duration for smoother effect */
  transition: color 0.8s ease-in-out, text-shadow 0.8s ease-in-out;
  /* Set consistent font weight */
  font-weight: 450 !important;
  /* Default state - no glow */
  text-shadow: 0 0 0 rgba(152, 153, 137, 0);
  color: #999999;
}

/* Enhanced glow for active state */
.nav-link.active,
.desktop-header nav ul li a.active,
.desktop-header nav ul li button.nav-link.active,
.dropdown-item.active,
.active > a {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(152, 153, 137, 0.7);
  /* Keep font weight consistent */
  font-weight: 450 !important;
}

/* Hover effect should be slightly less intense than active */
.desktop-header nav ul li a:hover,
.desktop-header nav ul li button.nav-link:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 6px rgba(152, 153, 137, 0.5);
}

/* Remove any remaining font-weight transitions */
.nav-link.active,
.desktop-header nav ul li a.active,
.desktop-header nav ul li button.nav-link.active,
.dropdown-item.active,
.active > a {
  /* Ensure font-weight is consistent */
  font-weight: 450 !important;
}
